<template>
  <b-modal
    id="request-dashboard-modal"
    :modal-class="'default-modal'"
    :hide-footer="true"
    @hidden="hidden"
  >
    <div class="text-center">
      <h2 class="highlighted-color1">
        <span>{{ headerTitle }}</span>
      </h2>
      <p>{{ headerDescription }}</p>
    </div>
    <AlertWraper
      :content="content"
      :type="content_type"
    />
    <div class="mt-3 default-form">
      <form class="default-form" @submit.prevent="">
        <div class="mt-3 text-center">
          {{ $t('advantages_dashboard') }}
        </div>
        <div class="mt-3 text-center">
          <Button
            class="btn btn-primary"
            @click="submit"
            :loading="loader"
          >
            {{ buttonText }}
          </Button>
        </div>
        </form>
    </div>
  </b-modal>
</template>

<script>

import NotificationService from '@/modules/notifications/services/notifications-service'
import { parseErrorResponseToArray } from '@/utils'

import AlertWraper from '@/components/common/Alert/AlertWraper'
import Button from '@/components/common/Button/Button'
import DashboardsService from '@/modules/dashboards/services/dashboards-service'
import { mapState } from 'vuex'

export default {
  name: 'RequestDashboardModal',
  props: ['buttonText', 'headerDescription', 'headerTitle'],
  components: {
    AlertWraper,
    Button
  },
  data () {
    return {
      content: null,
      content_type: 'success',
      loader: false
    }
  },
  computed: {
    ...mapState('users', {
      user: state => state.user
    })
  },
  methods: {
    /**
     * On modal hidden
     */
    hidden () {
      this.content = null
    },
    /**
     * Submit form
     */
    async submit () {
      this.loader = true

      try {
        const response = await DashboardsService.requestDashboard({
          userId: this.user.id,
          companyId: this.user.company.id,
          advertiserId: this.user.company.advertiser.id
        })
        this.content = response.message

        const notification = {
          notification: {
            type: 'success',
            content: response.message
          }
        }
        NotificationService.createGlobalSuccessNotification(notification)
      } catch (error) {
        this.content_type = 'error'
        this.content = parseErrorResponseToArray(error)
      }
      this.loader = false
    }
  }
}
</script>
